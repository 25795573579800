// Add your variable customizations of layout here

// Layout sizes
$mobileHeaderHeight: 45px;
$basePageSimpleHeaderHeight: 46px;
$slimMenuWidth: 50px;
$overlayMenuWidth: 235px;
$contextMenuCollapsedWidth: 2em;
$utilityMenuWidth: 2em;
$utilityMenuHeaderHeight: 36px;
$minimumSiteWidth: 360px;
$availableDrawerHeight: 164px;
$availableActionDrawerHeight: 209px;
$availableParameterHeaderWidth: calc(100% - 32px);
$standardButtonSetHeight: 51px;

// Display
$footerHidden: true;

// Fonts
$standardFontSize: 1em;
$subTextSize: .9rem;
$pageTitleSize: 1.5rem;
$pageSubTitleSize: 1.05rem;

// Padding
$tinyPadding: .125em;
$smallPadding: .25em;
$standardPadding: .5em;
$threeQuarterPadding: .75em;
$nestedContentPadding: 1em;
$utilityMenuContentPadding: 2.5em;
$utilityMenuAndContentPadding: 3.5em;

// Breakpoints
$largeDesktop: 1360px;
$desktop: 1024px;
$tablet: 800px;
$mobile: 640px;
$smallMobile: 500px;

// Standards
$standardBorderRadius: 4px;
$standardTransition: 0.25s ease-in-out;
$standardDisabledOpacity: 0.65;

// Z Indexes
$zIndexAboveLayoutMask: 999999998;

// Basic Avalon theme variables
@import '../../sass/layout/_variables';
