/* You can add global styles to this file, and also import other style files */

@import './assets/sass/overrides/_layout_variables';
@import './assets/sass/overrides/_theme_variables';

/* Override third party controls */
.stn-wdgt {
	display: none;
	z-index: 0;

	:first-child {
		:first-child {
			opacity: 1 !important;
			visibility: visible !important;
			width: 35px;
			height: 35px;
			bottom: 10px !important;
			left: unset !important;
			transform: unset !important;
			pointer-events: auto;
			cursor: pointer;
		}
	}

	.stn-compact-widget-loader {
		display: none;
	}
}

#genesys-messenger {
	z-index: 0;
	overflow: hidden;

	#gcobrowse-tooltip-container {
		display: none !important;
	}

	#gcobrowse-sharer-toolbar {
		opacity: 1;
		height: 89px !important;
		left: calc(50% - 140px) !important;
	}

	iframe:not(#gcobrowse-sharer-toolbar) {
		z-index: 0;
		width: 382px !important;
		left: unset !important;
		max-height: unset !important;
		clip-path: inset(0px 10px 0px 12px);
		height: calc(100% - #{$utilityMenuHeaderHeight} + 2px) !important;

		top: 10000px;
		right: 10000px;
	}

	&.visible {
		iframe {
			opacity: 1;
			transition: opacity 0.5s ease-in-out;

			&.genesys-mxg-launcher-frame {
				display: none;
			}

			&:not(.genesys-mxg-launcher-frame, #gcobrowse-sharer-toolbar) {
				top: calc(1px + #{$utilityMenuHeaderHeight}) !important;
				right: -10px !important;
			}

			&#gcobrowse-sharer-toolbar {
				top: calc(1px + #{$utilityMenuHeaderHeight});
			}
		}

		&.drawer-two-display {
			iframe {
				&:not(.genesys-mxg-launcher-frame, #gcobrowse-sharer-toolbar) {
					right: 350px !important;
				}
			}
		}

		&.drawer-three-display {
			iframe {
				&:not(.genesys-mxg-launcher-frame, #gcobrowse-sharer-toolbar) {
					right: 710px !important;
				}
			}
		}

		&.drawer-four-display {
			iframe {
				&:not(.genesys-mxg-launcher-frame, #gcobrowse-sharer-toolbar) {
					right: 1070 !important;
				}
			}
		}

		&.drawer-five-display {
			iframe {
				&:not(.genesys-mxg-launcher-frame, #gcobrowse-sharer-toolbar) {
					right: 1430px !important;
				}
			}
		}
	}

	&.hidden {
		iframe:not(#gcobrowse-sharer-toolbar) {
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
			top: 10000px !important;
			right: 10000px !important;
		}
	}

	#genesys-thirdparty {
		display: none;
	}
}

/* Elements */
/* Use: [attr.disabled]="truthy" on anchor tags */
a[disabled="true"] {
	opacity: .9;
	cursor: default !important;
	pointer-events: none;
}

hr {
	border: 0;
	height: 1px;
	background-image:
		linear-gradient(
			to right,
			rgba(0, 0, 0, 0),
			rgba(119, 119, 119, .65),
			rgba(0, 0, 0, 0));
}

input:focus-visible,
	select:focus-visible,
	textarea:focus-visible,
	button:focus-visible,
	span:focus-visible {
	outline: none;
}

::-webkit-input-placeholder {
	font-size: $subTextSize;
	font-style: italic;
}

:-moz-placeholder { /* Firefox 18- */
	font-size: $subTextSize;
	font-style: italic;
}

::-moz-placeholder { /* Firefox 19+ */
	font-size: $subTextSize;
	font-style: italic;
}

:-ms-input-placeholder {
	font-size: $subTextSize;
	font-style: italic;
}

// classes
.border-bottom {
	border-bottom: $overlayContentBorder;
}

.box-shadow {
	box-shadow: $standardBoxShadow;
}

.button-menu-navigation {
	.dropdown-button {
		width: 100%;
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
		height: 31px !important;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		border-top: none !important;
		border-right: none !important;
		border-left: none !important;
		border-bottom: 4px solid;
		background: $panelContentBg !important;
		font-weight: 600;

		&:active {
			border-color: none;
		}

		&:focus {
			box-shadow: none;
		}

		&:hover {
			background: $shade200 !important;
		}
	}

	.operation-button-menu {
		width: calc(100% - #{$standardPadding}) !important;
		border-top-right-radius: 0 !important;
		border-top-left-radius: 0 !important;
		margin-top: -3px;
	}
}

.button-set {
	a, button {
		margin: $smallPadding;
		display: inline-block;
	}

	.primary-action-button {
		margin-left: $threeQuarterPadding;
		right: $standardPadding;
	}

	.secondary-action-button {
		padding: $standardPadding;
		display: inline-block;

		&:hover {
			filter: $standardHover;
		}
	}
}

.button-bar-tooltip {
	margin-top: -$standardPadding;
	min-width: 225px !important;

	&.p-tooltip-top {
		margin-top: -15.5px;
	}
}

.flex-center {
	display: flex;
	justify-content: center;
}

.custom-field-wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	min-height: 73px;
}

.data-column {
	width: 50%;
}

.ui-content-small-phone {
	.data-column {
		width: 100%;
	}
}

.data-label {
	font-weight: 600;
}

.data-value {
	font-weight: 400;
}

.disabled-link {
	pointer-events: none;
	cursor: not-allowed;
	color: $secondaryButtonBg;
	opacity: $disabledOpacity;

	a {
		color: $secondaryButtonBg;
	}

	i {
		color: $secondaryButtonBg;
	}
}

.error-background {
	background-color: $red;
}

.error-color {
	color: $red;
}

.input-label {
	width: max-content;
	display: block;
	position: relative;
	min-height: 1.5em;
	margin-top: -4px;
}

.font-italic {
	font-style: italic;
}

.full-width {
	width: 100%;
}

.key-value-table {
	margin: 0 auto !important;
	border: $panelContentBorder;
	border-radius: $standardBorderRadius;
	.table, caption, tr, td {
 		font-weight: bold;
	}
}

.label-info-icon {
	font-size: 1em;
	position: relative;
	top: -1px;
}

.left-border-radius {
	border-top-left-radius: $standardBorderRadius !important;
	border-bottom-left-radius: $standardBorderRadius !important;
}

.loading-spinner {
	padding-top: $standardPadding;
	padding-bottom: $standardPadding;
	font-size: 2em;
}

.nested-display-actions {
	float: right;
	position: relative;
	bottom: 54px;
	margin-bottom: -22px;
	font-size: 17px;
	line-height: 17px;

	i {
		display: inline-block;

		&:hover {
			filter: $standardHover;
		}

		&.selected-bottom-border {
			padding-bottom: 3px;
		}
	}
}

.nested-display-header {
	border-bottom: $overlayContentBorder;
	padding-bottom: 5px !important;
	margin-left: -$standardPadding;
	width: calc(100% + #{$nestedContentPadding});

	.nested-display-back-container {
		&:hover {
			filter: $standardHover;
		}

		.nested-display-back-icon {
			margin-left: $nestedContentPadding;
		}

		.nested-display-back {
			position: relative;
			bottom: 2px;
		}
	}
}

.nested-display-subtitle {
	font-size: 1.15em;
	color: $textSecondaryColor;
	font-weight: 700;
	padding: $smallPadding $standardPadding;
}

.operation-menu-tooltip {
	min-width: 150px !important;

	&.p-tooltip-left {
		margin-top: 12px;
		margin-left: -20px;
	}

	&.p-tooltip-right {
		margin-top: $threeQuarterPadding;
		margin-left: 15.5px;
	}

	&.p-tooltip-top {
		margin-top: -$standardPadding;
	}
}

.panel-text {
	min-height: 100px;
	max-height: 20vh;
	overflow-y: auto;
	border-radius: $standardBorderRadius;
	border: $panelContentBorder;
	background-color: $panelContentBg;
	padding: $smallPadding $standardPadding $standardPadding $standardPadding !important;
	white-space: pre-wrap;
}

.panel-title {
	font-size: $pageTitleSize;
	font-weight: bold;
}

.panel-sub-title {
	font-size: $pageSubTitleSize;
	font-weight: bold;
}

.right-border-radius {
	border-top-right-radius: $standardBorderRadius !important;
	border-bottom-right-radius: $standardBorderRadius !important;
}

.search-filter {
	.p-inputtext {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.p-sidebar-mask {
	display: none !important;
}

.small-text {
	padding: .5rem;
}

.success-background {
	background-color: $green;
}

.success-color {
	color: $green;
}

.tabmenu-container {
	display: inline-block;
	position: relative;
	width: fit-content;
	top: 6.5px;

	.p-tabmenu {
		width: fit-content;
	}

	.btn-link {
		white-space: nowrap;
	}

	.dropdown-navigation-container {
		width: calc(100%);
	}
}

.standalone-tabmenu-container {
	top: 14.5px;
}

.transparent {
	color: transparent;
	background-color: transparent;
}

.validation-message-container {
	position: relative;
	display: flex;
	width: 100%;
}

.validation-message {
	position: absolute;
	top: -4px;
	left: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.9em;
	font-weight: 600;
	color: $errorMessageTextColor;
}

.validation-message:empty {
	display: none;
}

.warning-background {
	background-color: $orange;
}

.warning-color {
	color: $orange;
}

// Text style helpers
.ellipsis-text {
	display: inline-block;
	vertical-align: middle !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100%);

	p {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.text-center {
	text-align: center !important;
}

.text-indent {
	text-indent: $standardPadding;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.text-link {
	cursor: pointer;
}

.font-smaller
{
	font-size: smaller;
}

.font-larger {
	font-size: larger;
}

.font-bold {
	font-weight: bolder;
}

.font-bolder {
	font-weight: bolder;
}

.double-font-size {
	font-size: 2rem;
}

.triple-font-size {
	font-size: 3rem;
}

// Display style helpers
.square-bullets {
	list-style-type: square !important;
	padding: revert !important;
}

.centered-container {
	display: flex;
	justify-content: center;
}

.cursor-default {
	cursor: default !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-text {
	cursor: text !important;
}

.display-none {
	display: none !important;
}

.inline-display {
	display: inline;
}

.flex-display {
	display: flex;
}

.float-left {
	float:left;
}

.float-right {
	float:right;
}

.inline-block {
	clear:left;
	display:inline-block
}

.navigation-entity-header {
	margin-bottom: -12px;
}

.no-events {
	pointer-events: none;
}

.no-horizontal-padding {
	padding-left: 0px;
	padding-right: 0px;
}

.no-vertical-padding {
	padding-top: 0px;
	padding-bottom: 0px;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-margin-right {
	margin-right: 0 !important;
}

.no-margin-left {
	margin-left: 0 !important;
}

.no-padding {
	padding: 0;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-scroll {
	pointer-events: none;
	overflow: hidden;
}

.no-scrollbar {
	scrollbar-width: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-x-visible {
	overflow-x: visible !important;
}

.overflow-x-auto {
	overflow-x: auto !important;
}

.overflow-y-visible {
	overflow-y: visible !important;
}

.overflow-y-auto {
	overflow-y: auto !important;
}

.position-absolute {
	position: absolute;
}

.position-relative {
	position: relative;
}

.rounded-left-border {
	border-top-left-radius: $standardBorderRadius !important;
	border-bottom-left-radius: $standardBorderRadius !important;
}

.rounded-right-border {

	border-top-right-radius: $standardBorderRadius !important;
	border-bottom-right-radius: $standardBorderRadius !important;
}

.shortened-entity-header {
	margin-bottom: -$smallPadding;
}

.standard-border-radius {
	border-radius: $standardBorderRadius;
}

.standard-top-padding {
	padding-top: $standardPadding !important;
}

.standard-margin {
	margin: $standardPadding;
}

.standard-hover {
	filter: $standardHover;
}

/* Content needs to be wrapped in position relative */
.vertical-centered {
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

/* Screenreader display only */
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Media queries */
.multi-level-tab-shadow {
	top: 111.5px;
	z-index: 2;
}

.ui-content-tablet,
	.ui-content-small-phone,
		.ui-content-phone,
			.ui-content-large-tablet {
	.multi-level-tab-shadow.information-menu-offset {
		top: 208.5px;
	}
}

.ui-content-desktop,
	.ui-content-large-desktop {
	.multi-level-tab-shadow.information-menu-offset {
		top: 124.5px;
	}
}

@media (max-width: $tablet) {
	.ui-content-tablet,
		.ui-content-small-phone,
			.ui-content-phone {
		.multi-level-tab-shadow {
			top: 155.5px;

			&.information-menu-offset {
				top: 253.5px;
			}
		}
	}

	.stn-wdgt {
		.stn-widget-buttons-wrapper {
			top: $smallPadding !important;
			right: $smallPadding !important;
		}
	}

	#genesys-messenger {
		iframe {
				height: calc(100% - #{$utilityMenuHeaderHeight} - #{$mobileHeaderHeight} - 2px) !important;
			}

		&.visible {
			iframe {
				&:not(.genesys-mxg-launcher-frame) {
					top: calc(6px + #{$utilityMenuHeaderHeight} + #{$mobileHeaderHeight}) !important;
				}
			}
		}
	}
}

.custom-tooltip {
	background: $tooltipBg;
	color: $tooltipTextColor;
	padding: $standardPadding;
	box-shadow: $inputOverlayShadow;
	border-radius: $borderRadius;
}

.p-tooltip {
	max-width: calc(50vh);
	max-height: 45em !important;

	&.ui-content-small-phone,
		&.ui-content-phone,
		&.ui-content-tablet {

		&:not(.history-tabs-tooltip)
		{
			left: 0 !important;
			margin-left: 2.5em;

			.p-tooltip-arrow{
				display:none;
			}
		}
	}

	.p-tooltip-text {
		overflow-y: auto;
		max-height: 40em;
		display: block;
	}
}

.p-input-div {
	.menu-item-wrapper {
		max-width: 50%;
		overflow-y: hidden;
		max-height: 35px;
	}

	.ellipsis-menu {
		z-index: 5;
		position: absolute;
		width: 240px;
		border-radius: 4px;
		border: $panelContentBorder;
		background-color: $panelContentBg;

		&.ui-content-small-phone,
			&.ui-content-phone{
			width: 175px;
		}

		.ellipsis-menu-container {
			operation-menu > div > li{
				transform: none !important;
				offset: none !important;
				opacity: 1 !important;
			}

			.p-scrollpanel-content {
				max-height: 165px;
				padding: 0 !important;
				width: calc(100%);
				scrollbar-width: none;
				-ms-overflow-style: none;
			}

			.p-scrollpanel-content::-webkit-scrollbar {
				display: none;
			}

			.p-scrollpanel-bar-x {
				display: none !important;
			}
		}
	}

	.p-inputgroup {
		.input-group, .dropdown-group {
			min-width: 50% !important;
		}

		.p-inputtext, .p-dropdown {
			width: 100% !important;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}

		.p-button {
			margin-right: $tinyPadding !important;
			max-height: 2.5em;
			padding: $standardPadding;
			max-width: min-content;

			.p-button-label{
				display: inline-block;
				vertical-align: middle !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.p-button-icon{
				font-size: 1.25em;
				position: relative;
				top: $tinyPadding;
			}

			.p-button-icon.p-button-icon-left.pi-spinner {
				top: 0;
				margin-right: $smallPadding;
			}
		}

		.menu-item-wrapper {
			max-width: 50%;
			overflow-y: hidden;
			max-height: 35px;

			span {
				&:first-child {
					.p-button {
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}

				&:last-child {
					.p-button {
						margin-right: 0 !important;
					}
				}
			}
		}
	}
}

.tooltip-icon-cursor {
	cursor: pointer;
}