// Add your variable customizations of theme here

// Borders
$tabMenuBorderSize: 3px;
$nestedContentBoxShadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.26);
$raisedBoxShadow: 0 1px 7px 0 rgba(0, 0, 0, 0.26);
$standardBoxShadow: 0 1px 4px 0 rgba(0, 0, 0, 0.26);
$selectedBoxShadow: 0 0 4px 0 rgba(0, 0, 0, 0.26);
$horizontalDividerBorderTop: 1px solid darken(rgb(247, 247, 247), 15%) !important;
$thickHorizontalDividerBorderTop: 2px solid darken(rgb(247, 247, 247), 15%) !important;

// Hovers
$standardHover: brightness(85%);

// Basic Avalon color variables
@import '../../sass/theme/_variables';