$fontFamily:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$fontSize:14px;
$textColor:#292B2C;
$textSecondaryColor:#777777;
$borderRadius:4px;
$dividerColor:#e5e5e5;
$transitionDuration:.3s;
$disabledBgColor:#eeeeee;

/* Predefined Colors */
$lightestGray:#f5f5f5;
$lightGray:#cccccc;
$gray:#999999;
$darkGray:#777777;
$white:#ffffff;

$amber: #FF4E3D;
$blue:#007bff;
$purple:#9189fd;

$lightBlue:#8dc8ff;
$pink:#f790c8;
$indigo:#6610f2;
$lightGreen:#00E37C;
$green:#3e9018;
$red:#da2f31;
$lightOrange:#ffbf79;
$orange:#ffb200;
$darkOrange:#fd901c;
$teal:#599597;
$black:#000000;
$yellow:#ffd644;


$inputInvalidBorderColor:#b94a48;
$inputInvalidBgColor:#ffffff;

$menuHoverBgColor:#f4f4f4;
$bodyBgColor:#EEF2F6;